body {
    background-color: #121212;
    color: #ffffff;
    line-height: 1.6;
    padding: 0;
    margin: 0;
    font-family: "Poppins", sans-serif;
}

.app-container {
    margin: 10px;
}

.app-title {
    margin: 0 0 10px 0;
    text-align: center;
}

.home-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 100px);
    gap: 50px;
}

.home-button {
    width: 250px;
    height: 150px;
    font-size: 20px;
    border: none;
    border-radius: 16px;
    background-color: #bb86fc;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: "Poppins", sans-serif;
}

.home-button:hover {
    background-color: #9a67ea;
}

.app-nav {
    display: none;
}

.video-call {
    margin: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.session-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 8px;
    max-width: 300px;
    width: 100%;
}

.session-details p {
    margin: 0;
    font-size: 12px;
    color: #555;
    display: flex;
    align-items: center;
}

.session-details .label {
    font-weight: bold;
    color: #000;
}

.session-details .content {
    color: #555;
    margin-left: 5px;
    display: flex;
    align-items: center;
}

.session-details .content span {
    margin-left: 5px;
}

.session-details .icon {
    cursor: pointer;
    margin-left: 5px;
    font-size: 10px;
}

.camera-settings {
    display: flex;
    align-items: center;
    gap: 10px;
}

.camera-settings label {
    font-size: 14px;
    color: #bb86fc;
}

.btn-primary,
.btn-secondary,
.btn-danger {
    padding: 10px 20px;
    font-size: 14px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    gap: 10px;
    margin: 0 10px 0 0;
    transition: background-color 0.3s ease;
    background-color: #007bff;
}

.btn-primary:hover,
.btn-secondary:hover {
    background-color: #0056b3;
}

.btn-danger {
    background-color: #dc3545;
}

.btn-danger:hover {
    background-color: #c82333;
}

.video-container {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.video-box {
    width: calc(50% - 10px);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.video-stream {
    width: 100%;
    max-height: 250px;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
}

.video-box p {
    font-size: 12px;
    color: #555;
    text-align: center;
    margin: 0;
}

.button-container {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.input-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 15px;
    width: 25vw;
}

.input-label {
    font-size: 14px;
    color: #bb86fc;
}

.input-field,
.camera-select {
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    outline: none;
    box-sizing: border-box;
}

.input-field:focus {
    border-color: #007bff;
}

.camera-select {
    height: 35px;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAVklEQVR4nGNgGAWjYBQMGIhhYGD4wcDA8J9IDFIbRYoF8SQY/h+K40j1RTkJhteTajgpltSTazgxltRTajg+S6hmODZLqG44DCQxMDAkwnmjYBQMDwAAptU8wdV6fT0AAAAASUVORK5CYII=');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 15px;
    padding-right: 35px;
}

.error-message {
    color: #ff5252;
    font-size: 12px;
    margin-top: 5px;
}

.session-info h3 {
    color: green;
}
